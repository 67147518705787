import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-8">
        <section>
          <h1 className="text-3xl font-semibold text-center text-gray-900">Privacy Policy</h1>
          <p className="mt-4 text-lg text-gray-700 text-center">
            Welcome to the Aves Security Privacy Policy. This document explains how we collect, use, share, and safeguard information about you. References to "Aves Security," "we," "us," and "our" pertain to the Aves Security platform. This Privacy Policy applies to our website (www.avessecurity.com), our mobile applications, and all services associated with this Privacy Policy (collectively referred to as the "Services").
          </p>
          <p className="mt-4 text-lg text-gray-700 text-center">
            By accessing our Services or creating an account, you consent to our collection, processing, usage, retention, and sharing of your Personal Data as described in this Privacy Policy. If you do not agree to these terms, please refrain from using our Services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Information We Collect</h2>
          <ul className="list-disc pl-8 mt-4 text-lg text-gray-700">
            <li><strong>Non-Personal Information:</strong> This includes anonymous usage data, demographic information, referring/exit pages, platform types, preferences, and other general statistical information.</li>
            <li><strong>Personal Information:</strong> Personal Data includes any information that can identify you, such as your name, address, phone number, email address, payment details, or any other identifiable information.</li>
            <li><strong>Geo-Location Information:</strong> With your consent, we may collect your precise location data via mobile devices or applications to improve your experience.</li>
            <li><strong>Information About Reports:</strong> We collect data related to reports, comments, attachments, and daily updates submitted through our platform.</li>
            <li><strong>Support Ticket Information:</strong> If you submit a support ticket, we use the information provided solely to address and resolve your request.</li>
            <li><strong>Public Information:</strong> Information you make publicly available on forums or social media may be collected and used in line with this Privacy Policy.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Automatic Data Collection</h2>
          <ul className="list-disc pl-8 mt-4 text-lg text-gray-700">
            <li>IP addresses</li>
            <li>Browser type</li>
            <li>Operating system</li>
            <li>Device identifiers</li>
            <li>Browsing behavior</li>
            <li>Location data</li>
            <li>Content and advertisements interacted with</li>
          </ul>
          <p className="mt-4 text-lg text-gray-700">
            We use cookies and similar technologies to track user activity, remember preferences, and improve overall user experience.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Use of Information</h2>
          <ul className="list-disc pl-8 mt-4 text-lg text-gray-700">
            <li>Provide, maintain, and improve our Services</li>
            <li>Personalize user experiences</li>
            <li>Communicate with you about updates or offers</li>
            <li>Respond to support requests</li>
            <li>Analyze trends and monitor performance</li>
          </ul>
          <p className="mt-4 text-lg text-gray-700">
            We do not sell, trade, or rent your Personal Data to third parties for marketing purposes without your consent.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Data Security</h2>
          <p className="mt-4 text-lg text-gray-700">
            We implement industry-standard security measures, including encryption, secure servers, and access controls, to safeguard your data from unauthorized access, loss, or disclosure.
          </p>
          <p className="mt-4 text-lg text-gray-700">
            In case of a data breach, we will promptly notify affected users and take all necessary actions to mitigate the risk.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Children's Privacy</h2>
          <p className="mt-4 text-lg text-gray-700">
            Our Services are not directed to individuals under the age of 13, and we do not knowingly collect personal information from children.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Managing Communication Preferences</h2>
          <p className="mt-4 text-lg text-gray-700">
            You can opt out of receiving promotional emails by following the unsubscribe instructions included in our emails. However, important service-related messages cannot be opted out of.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">California Privacy Rights</h2>
          <p className="mt-4 text-lg text-gray-700">
            In compliance with the California Online Privacy Protection Act, users can review, update, or delete their personal information via their account settings.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Changes to This Policy</h2>
          <p className="mt-4 text-lg text-gray-700">
            We may update this Privacy Policy from time to time. Significant changes will be communicated via email or a prominent notice on our website. Please review this page periodically for updates.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900">Contact Us</h2>
          <p className="mt-4 text-lg text-gray-700">
            For any questions regarding this Privacy Policy, please contact us at <a href="mailto:aves.managementsys@gmail.com" className="text-blue-600">aves.managementsys@gmail.com</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import Aves from '../assets/aveslogo.png';
import Profile from '../assets/Profile.png'; 
import UserProfileImage from '../assets/Profile.png'; 
import axios from 'axios';
import ReactQRCode from 'react-qr-code';


function Securitypass({ securityId }) {
    const [profileImage, setProfileImage] = useState(Profile); 
    const [userProfileImage, setUserProfileImage] = useState(UserProfileImage);
    const [formData, setFormData] = useState({
      name: '',
      idProof: '',
      phoneNumber: '',
      email: '',
      whatsappNumber: '',
      emergencyContact: '',
      description: ''
    });


    const [hasAccess, setHasAccess] = useState(true); 
    const [qrCodeData, setQrCodeData] = useState(null);
    // useEffect(() => {
    //    const hasVisited = localStorage.getItem('hasVisitedSecurityPassPage');
    //    if (hasVisited) {
    //      // If the page has been visited before, block access
    //     setHasAccess(false);
    //  } else {
    //      // Mark the page as visited
    //    localStorage.setItem('hasVisitedSecurityPassPage', 'true');
    //   }
    // }, []);
    
    const [securityData, setSecurityData] = useState(null);
  //
    // Fetch security data when the component mounts
    useEffect(() => {
      const fetchSecurityData = async () => {
        try {
          const response = await axios.get(`http://api.avessecurity.com:6378/api/securitypass/get/${securityId}`);
          const data = response.data;
  
          // Update form data with fetched values
          setFormData({
            name: data.name || '',
            idProof: data.idProof || '',
            phoneNumber: data.phoneNumber || '',
            email: data.email || '',
            whatsappNumber: data.whatsappNumber || '',
            emergencyContact: data.emergencyContact || '',
            description: data.description || ''
          });
  
          // Set profile and user profile images
          setProfileImage(data.profileImage || Profile);
          setUserProfileImage(data.userProfileImage || UserProfileImage);
        } catch (error) {
          console.error('Error fetching security data', error);
        }
      };
  
      fetchSecurityData();
    }, [securityId]);
  
    const handleImageChange = (e, setter) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setter(reader.result); 
        };
        reader.readAsDataURL(file); 
      }
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = new FormData();
      data.append('name', formData.name);
      data.append('idProof', formData.idProof);
      data.append('phoneNumber', formData.phoneNumber);
      data.append('email', formData.email);
      data.append('whatsappNumber', formData.whatsappNumber);
      data.append('emergencyContact', formData.emergencyContact);
      data.append('description', formData.description);
      data.append('profileImage', profileImage);
      data.append('userProfileImage', userProfileImage);
  
      try {
        const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NzU2ZDI4ODFmNWExODc3N2I3OWZmYWQiLCJ1c2VybmFtZSI6IkF2ZXMiLCJyb2xlIjoiU3VwZXIgQWRtaW4iLCJpYXQiOjE3MzQxNTM4MTYsImV4cCI6MTc5NzIyNTgxNn0.RxZNd9DhdJdK33iUzuHWgwDKiwXFa91aS-hWtrGbApY'; // Get your token dynamically from a secure source
        const response = await axios.put(`http://api.avessecurity.com:6378/api/securitypass/update/${securityId}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });
  
        // Handle successful response
        if (response.status === 200) {
          alert("Your details have been successfully updated!");
        }
      } catch (error) {
        // Handle error response
        console.error('Error while submitting form', error.response ? error.response.data : error.message);
        alert("Update failed. Please try again.");
      }
    };
  
    return (
      <div>
      <main>
        <section className="bg-[#27335f] mt-[-130px] w-full pb-10 h-screen flex flex-col justify-center items-center sm:h-full md:h-full lg:h-full xl:h-full">
          <div className='container flex flex-col justify-center items-center relative top-[-180px] pb-7'>
            <div className="flex justify-center items-center mb-4">
              <img className="w-32 pt-72 sm:w-48 sm:pt-32 md:pt-40 md:w-64 lg:w-80 lg:pt-44 xl:w-96 xl:pt-1 h-auto" src={Aves} alt="Aves Logo" />
            </div>

            <div className="flex justify-center items-center mb-4">
              <h1 className="text-2xl sm:text-3xl md:text-4xl text-white font-semibold">SECURITY PASS</h1>
            </div>

            <div className="flex justify-center items-center mb-6">
              <p className="text-lg sm:text-xl md:text-2xl text-white text-center px-4 font-medium">Fill in your details and receive a confirmation in your email</p>
            </div>

            {!hasAccess ? (
              <div className="flex justify-center items-center mb-6">
                <p className="text-lg sm:text-xl md:text-2xl text-white font-medium">
                  You don't have access to this page anymore.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="container shadow-xl p-4 sm:p-6 md:p-8 w-full max-w-lg bg-white rounded-lg">
                <div className="flex flex-col items-center space-y-4">
                  <input 
                    type="text" 
                    name="name"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="NAME"
                    required
                  />

                  <div className="text-center">
                    <label htmlFor="profile-image" className="cursor-pointer">
                      <img 
                        src={profileImage} 
                        alt="Profile" 
                        className="w-24 h-24 mx-auto mb-2 rounded-full object-cover"
                      />
                      <h2 className="text-sm text-gray-700 font-bold">Upload</h2>
                    </label>
                    <input 
                      id="profile-image" 
                      type="file" 
                      accept="image/*" 
                      className="hidden" 
                      onChange={(e) => handleImageChange(e, setProfileImage)} 
                    />
                  </div>

                  <input 
                    type="text" 
                    name="idProof"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700" 
                    placeholder="IDPROOF"
                    value={formData.idProof}
                    onChange={handleInputChange}
                    required
                  />

                  <div className="text-center">
                    <label htmlFor="user-profile-image" className="cursor-pointer">
                      <img 
                        src={userProfileImage} 
                        alt="User Profile" 
                        className="w-24 h-24 mx-auto mb-2 rounded-full object-cover"
                      />
                      <h2 className="text-sm text-gray-700 font-bold">Upload</h2>
                    </label>
                    <input 
                      id="user-profile-image" 
                      type="file" 
                      accept="image/*" 
                      className="hidden" 
                      onChange={(e) => handleImageChange(e, setUserProfileImage)} 
                    />
                  </div>

                  <input 
                    type="text" 
                    name="phoneNumber"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700" 
                    placeholder="PHONE NUMBER"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <input 
                    type="email" 
                    name="email"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700" 
                    placeholder="EMAIL ADDRESS"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <input 
                    type="text" 
                    name="whatsappNumber"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700" 
                    placeholder="WHATSAPP NUMBER"
                    value={formData.whatsappNumber}
                    onChange={handleInputChange}
                  />
                  <input 
                    type="text" 
                    name="emergencyContact"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-10 p-2 text-gray-700" 
                    placeholder="EMERGENCY CONTACT"
                    value={formData.emergencyContact}
                    onChange={handleInputChange}
                  />
                  <textarea 
                    name="description"
                    className="rounded-lg w-full sm:w-80 md:w-96 h-28 p-2 text-gray-700" 
                    placeholder="DESCRIPTION"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex justify-center items-center mt-6">
                  <button type="submit" className="bg-indigo-800 text-white w-40 h-12 rounded-3xl text-lg">
                    Send Request
                  </button>
                </div>
              </form>
            )}
          </div>
          {qrCodeData && (
            <div className="flex justify-center items-center mt-6">
              <ReactQRCode value={JSON.stringify(qrCodeData)} size={256} />
            </div>
          )}
        </section>
      </main>
    </div>
    );
  }
  
  export default Securitypass;
import React from 'react'
import Aves from '../assets/images/aveslogo.png'
import Form from '../components/Form'
import { Link } from 'react-router-dom'


function Service() {
  return (
    <div>
        <section>
            <div>
                <div className='flex justify-center items-center pt-5 p-10 '>
                    <h1 className='text-base text-center font-bold text-[#27335f] lg:text-5xl lg:pt-3 xl:text-4xl xl:mb-12 '>
                    Smart Solutions for Modern Security Challenges
                    </h1>
                </div>
                <div className='flex justify-center items-center p-5 mt-[-50px] lg:p-20 xl:pt-0 xl:p-22 '>
                    <p className='text-sm text-[#27335f] font-normal text-center lg:text-xl xl:text-2xl  '>
                    AVES Security Management provides a suite of services that address the dynamic needs of today’s security landscape. Whether managing personnel, monitoring activities, or maintaining compliance, our platform simplifies and optimizes your security operations
                    </p>
                </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 ">
            <div className="text-[#27335f] hover:text-white bg-white text-center p-5 shadow-2xl rounded-md cursor-pointer hover:bg-[#27335f] group">
  <h1 className="text-2xl font-bold pb-1 group-hover:text-white cursor-pointer">Personnel Management</h1>
  <p className="text-sm font-normal text-slate-500 group-hover:text-white cursor-pointer">
    Streamline shift planning, training, and performance evaluations to ensure your team operates at peak efficiency
  </p>
</div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md hover:bg-[#27335f] group hover:text-white cursor-pointer group  ">
        <h1 className='text-2xl font-bold pb-1 cursor-pointer group-hover:text-white'>Incident Reporting
        </h1>
        <p className='text-sm font-normal text-slate-500 cursor-pointer group-hover:text-white'>Document, analyze, and respond to events in real time, improving accountability and response times</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md hover:bg-[#27335f] group hover:text-white cursor-pointer group ">
        <h1 className='text-2xl font-bold pb-1 cursor-pointer group-hover:text-white'>Data-Driven Insights</h1>
        <p className='text-sm font-normal text-slate-500 cursor-pointer group-hover:text-white'>Access powerful analytics and reporting tools to make informed decisions and optimize resource allocation</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md hover:bg-[#27335f] group hover:text-white cursor-pointer group ">
        <h1 className='text-2xl font-bold pb-1  cursor-pointer group-hover:text-white'>Customizable Solutions</h1>
        <p className='text-sm font-normal text-slate-500 cursor-pointer group-hover:text-white'>
        Tailor the platform to meet the specific requirements of your organization, ensuring maximum utility</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md  hover:bg-[#27335f] group hover:text-white cursor-pointer group ">
        <h1 className='text-2xl font-bold pb-1 cursor-pointer group-hover:text-white'> Go Paperless</h1>
        <p className='text-sm font-normal text-slate-500 cursor-pointer group-hover:text-white'>
        Say goodbye to messy paper records. Save your data online where you can sort, filter, and create reports anytime</p>
      </div>
      <div className="text-[#27335f] bg-white text-center p-5 shadow-2xl rounded-md   hover:bg-[#27335f] group hover:text-white cursor-pointer group  ">
        <h1 className='text-2xl font-bold pb-1 cursor-pointer group-hover:text-white'>Eco-Friendly</h1>
        <p className='text-sm font-normal text-slate-500 cursor-pointer group-hover:text-white'>
        Help the environment by using less paper and doing everything digitally</p>
      </div>
      <div className='flex justify-center items-center pt-5 p-10 '>
                    <h1 className='text-base text-center font-bold text-[#27335f] lg:text-5xl lg:pt-3 xl:text-4xl'>
                  
                    </h1>
                </div>
    </div>
    <div className='flex justify-center items-center '>
    <h1 className='text-base text-center font-bold text-[#27335f] mb-8 sm:mb-10 lg:text-5xl  lg:pt-3 xl:text-4xl p-5 xl:mb-12'>
    Transforming Security Across Diverse Industries
                    </h1>
    </div>
    <div className='flex justify-center items-center p-5 mt-[-50px] lg:p-20 xl:pt-0 xl:p-22 '>
                    <p className='text-sm text-[#27335f] font-normal text-center lg:text-xl xl:text-2xl  '>
                    The AVES Security Management App is designed to cater to the unique needs of various industries. With its versatile features and customizable solutions, our platform ensures safety, compliance, and efficiency in any environment. Here’s a look at the industries we empower with our cutting-edge security solutions
                    </p>
                </div>
            </div>
            <div class="container mx-auto p-5">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-10 ">
   {/*<!-- Card 1 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hospitality</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring.</p>
                <Link to="/" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>

        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 2 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Corporate & Office Spaces</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring..</p>
                <Link to="/Corporate" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 3 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Healthcare</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Managing guest safety, property security, and compliance with regulations.

How We Help: Streamline operations with tools for incident reporting, lost and found management, and staff monitoring.</p>
                <Link to="/Healthcare" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 4 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Education</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Maintaining a secure environment for students and staff, managing access control.

How We Help: Provide easy-to-use tools for tracking incidents, gate passes, and training modules for campus security staff.</p>
                <Link to="/Education" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 5 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Retail & Shopping Complexes</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Preventing theft, managing customer safety, and ensuring smooth daily operations.

How We Help:  Enable robust monitoring of staff and visitors, handle parcel logs, and manage reports effectively.</p>
                <Link to="/Residential" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 6 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Government & Public Sector</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Protecting public assets, handling large-scale operations, and ensuring data security.

How We Help: Equip teams with robust reporting systems, emergency management tools, and comprehensive training solutions</p>
                <Link to="/Government" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 7 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Residential Complexes & Gated Communities</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Ensuring the safety of residents, managing visitor access, and tracking incidents.

How We Help: Simplify gate pass issuance, staff monitoring, and compliance tracking for community safety</p>
                <Link to="/Residential" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/*<!-- Repeat Card -->*/}
        {/*<!-- Card 8 -->*/}
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Logistics & Transportation</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges: Securing assets in transit, managing compliance, and monitoring personnel.

How We Help: Facilitate incident reporting, key management, and efficient training for staff on the move..</p>
                <Link to="/Logistics" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>
        {/* Repeat Card */}
        {/* Card 9 */}
        <div class="max-w-sm p-1 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg" src={Aves} alt="Technology Acquisitions" />
            <div class="p-5">
                <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Manufacturing & Warehousing</h5>
                </a>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Key Challenges:  Ensuring the safety of goods, managing worker security, and complying with safety standards.

How We Help:   Facilitate first aid kit tracking, incident investigation, and compliance management.</p>
                <Link to="/Manufacturing" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read more
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </Link>
            </div>
        </div>

    </div>
    <Form/>
</div>

        </section>
    </div>
  )
}

export default Service
import React from 'react';
import Security from '../assets/images/Police.png';
import Form from '../components/Form';
import Gaurd from '../assets/SecuirtyGaurd.png';
import Hand  from '../assets/Malehand.png'

function About() {
  return (
    
    <section id="hero">
    <div className="bg-[#000D16] py-10">
      <div className="flex flex-col lg:flex-row-reverse items-center lg:items-center justify-center lg:justify-between lg:gap-10 px-5 lg:px-20">
        {/* Image Section */}
        <div className="flex justify-end relative left-20 lg:justify-end lg:w-1/2 xl:justify-end">
          <img
            src={Hand}
            alt="Security Illustration"
            className="max-w-full h-screen lg:max-h-[850px] object-contain"
          />
        </div>

        {/* Text Section */}
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold leading-tight">
            AVES SECURITY <br /> MANAGEMENT SYSTEM
          </h1>
          <p className="text-slate-300 text-lg mt-4">
            Empowering Security Operations  for a Safer Tomorrow
          </p>
          <p className="text-slate-300 text-lg mt-4 font-serif">      
AVES Security Management brings everything you need into one app. Whether it's managing teams, tracking incidents, ensuring compliance, or monitoring performance, we cover all aspects of security operations in a single, easy-to-use platform. 
          </p>
          <button className='bg-[#FABC3F] w-[132px] h-11 rounded-2xl mt-4 text-base font-bold text-gray-900'>
            LearnMore
          </button>

        </div>
      </div>
    </div>
    {/* Main Section */}
    <div className="container mx-auto mt-6 px-5 lg:px-20">
        <h1 className="text-center text-3xl font-bold text-[#27335f]">
          Comprehensive Security at Your Fingertips
        </h1>
        <p className="text-[20px] sm:text-[20px] lg:text-[20px]  font-thin text-center mt-4 text-[#27335f] mb-6">
          AVES Security Management goes beyond traditional security practices
          by offering a full range of solutions in one easy-to-use platform. Whether it's overseeing security teams, handling incidents, ensuring
          regulatory compliance, or monitoring key performance indicators, AVES   brings all aspects of security management together to simplify your
          operations and keep your organization protected.
        </p>
      </div>
      <Form />
  </section>
  );
}

export default About;

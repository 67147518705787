import { useState } from 'react';
import { Link } from 'react-router-dom';
import Aves from '../assets/Aves.png';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="bg-white shadow-md">
      <nav className="relative container mx-auto p-4 lg:px-10">
        {/* Flex Container */}
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={Aves}
              alt="Aves Logo"
              className="h-32 lg:h-16 xl:h-20 w-auto  cursor-pointer transition duration-500 hover:scale-110"
            />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            <Link
              to="/"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              About
            </Link>
            <Link
              to="/service"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Services
            </Link>
            <Link
              to="/plans"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Plans
            </Link>
          </div>

          {/* Get Started Button */}
          <Link
            to="#"
            className="hidden md:block p-3 px-6 text-white bg-blue-700 font-semibold rounded-full hover:bg-blue-800 transition duration-300"
          >
            Get Started
          </Link>

          {/* Hamburger Icon for Mobile Menu */}
          <button
            className="block md:hidden focus:outline-none"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <div className="space-y-2">
              <span className="block h-1 w-8 bg-gray-700"></span>
              <span className="block h-1 w-8 bg-gray-700"></span>
              <span className="block h-1 w-8 bg-gray-700"></span>
            </div>
          </button>
        </div>

        {/* Mobile Menu */}
        {toggleMenu && (
          <div className="md:hidden mt-6 flex flex-col items-center space-y-4 bg-white p-6 rounded-lg shadow-lg">
            <Link
              to="/"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              About
            </Link>
            <Link
              to="/service"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Services
            </Link>
            <Link
              to="/plans"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Plans
            </Link>
            <Link
              to="/contact"
              className="text-gray-700 font-semibold hover:text-blue-600 transition duration-300"
            >
              Contact
            </Link>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;

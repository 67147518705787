import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Mobile from '../assets/images/iPhone .png'

function HealthCareSecurity() {
  return (
    <div className='bg-white'>
        <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row '>
  {/* What's Different */}
  <div className='flex flex-col space-y-12 md:w-1/2'>
    <h2 className='max-w-md text-4xl font-bold text-[#27335f] text-center md:text-left'>
    Why AVES Healthcare Security App?
    </h2>
    <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
    Welcome to AVES Security Management App.We are experts in delivering comprehensive security solution for healthcare facilities through this modern healthcare security app. Our application has the best and advanced features and integrated platform that enhances the safety of your patients, employees and other important visions
    </p>
    <img
      className='p-4 lg:relative lg:-top-10 lg:p-2 lg:right-8 xl:relative xl:-top-10 xl:p-8'
      src={Ac} 
      alt="Aves Security Management System in action, illustrating key features."
    />
  </div>

  {/* Numbered List */}
  <div className='flex flex-col space-y-8 md:w-1/2'>
    {/* List Item 1 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            01
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Tailored for Healthcare
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Tailored for Healthcare
        </h3>
        <p className='text-darkGrayishBlue'>
        Developed for the most part to fit the needs of supply chain operations in a healthcare setting.

        </p>
      </div>
    </div>

    {/* List Item 2 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            02
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Real-Time Monitoring
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Real-Time Monitoring
        </h3>
        <p className='text-darkGrayishBlue'>
        Message notifications and updates on possible threats allow being one step ahead of the problem.
        </p>
      </div>
    </div>

    {/* List Item 3 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            03
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Simplified Operations
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Simplified Operations
        </h3>
        <p className='text-darkGrayishBlue'>
        Reduce complexities of security procedures and increase security operations effectiveness.

        </p>
      </div>
    </div>

    {/* List Item 4 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            04
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Customizable Features
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Customizable Features
        </h3>
        <p className='text-darkGrayishBlue'>
        Customize the app as a tool and a platform according to your healthcare organization.
        </p>
      </div>
    </div>

    {/* List Item 5 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            05
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Data Insights
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Data Insights
        </h3>
        <p className='text-darkGrayishBlue'>
        Use post-event reports and analyses in order to improve your security measures.
        </p>
      </div>

    </div>
  </div>
  <div>
  </div>
</div>
<p className='p-5 text-center text-3xl font-bold mb-4 text-[#27335f]'>

Most Vital Characteristics of Our Mobile Application for Healthcare Security
    </p>
    <div className='bg-white'>
        <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row '>
  {/* What's Different */}
  <div className='flex flex-col space-y-12 md:w-1/2'>
    <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
      Take the first step towards a safer and more efficient future. Aves Security Management System is your trusted partner in security management, offering innovative solutions that empower your team and secure your assets.
    </p>
    <img
      className='p-4 lg:relative lg:-top-10 lg:p-2 lg:right-8 xl:relative xl:-top-10 xl:p-8'
      src={Am} 
      alt="Aves Security Management System in action, illustrating key features."
    />
  </div>

  {/* Numbered List */}
  <div className='flex flex-col space-y-8 md:w-1/2'>
    {/* List Item 1 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            01
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Patient and Staff Safety

          </h3>
        </div>
      </div>
      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Patient and Staff Safety
        </h3>
        <p className='text-darkGrayishBlue'>
        Provide protection of patients, visitors and other health care workers.
        Supervise or observe the flow or frequentation within and around the place.
        </p>
      </div>
    </div>

    {/* List Item 2 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            02
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Access Control
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Access Control
        </h3>
        <p className='text-darkGrayishBlue'>
        Control admission of patients and visitors into limited zones such as Intensive care units, theatre, and the laboratory
        Biometric and digital pass which can be used for authorization of entry.
        </p>
      </div>
    </div>

    {/* List Item 3 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            03
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Incident Reporting
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Incident Reporting
        </h3>
        <p className='text-darkGrayishBlue'>
        Allow staff to submit occurrences in the app at the time of incident.
Centralized resolution system in order to respond faster.

        </p>
      </div>
    </div>
       {/* List Item 2 */}
       <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            04
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Access Control
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Access Control
        </h3>
        <p className='text-darkGrayishBlue'>
        Control admission of patients and visitors into limited zones such as Intensive care units, theatre, and the laboratory
        Biometric and digital pass which can be used for authorization of entry.
        </p>
      </div>
    </div>

    {/* List Item 3 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            05
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Visitor Management
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Visitor Management
        </h3>
        <p className='text-darkGrayishBlue'>
        Allow staff to submit occurrences in the app at the time of incident.
Centralized resolution system in order to respond faster.

        </p>
      </div>
    </div>

    {/* List Item 4 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            06
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Emergency Alerts
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold  md:block'>
        Emergency Alerts
        </h3>
        <p className='text-darkGrayishBlue  mb-20'>
        Use the mass alert when there is an emergency for example fire or a security break in.
Promote the efficient and subsequent practical strategies of evacuations.
        </p>
      </div>
    </div>
      </div>
    </div>
  </div>
  <div>
  </div>
  <h1 className='flex justify-center text-3xl font-bold text-center text-[#27335f] '>
    How It works
  </h1>
  <div className='flex justify-center items-center mt-8 mb-8'>
    <img className='w-60 h-[450px]' src={Mobile} alt='mobile'/>
  </div>
  <div className='flex justify-center items-center mb-2'>
    <h1 className='bg-[#27335f] text-3xl w-28 h-24 text-center flex justify-center items-center font-semibold text-white rounded-md'>
        3
    </h1>
  </div>
  <div className='flex justify-center items-center'>
    <p className='text-center  text-lg font-bold'>
    Start Securing
    </p>
  </div>
  <div className='flex justify-center items-center'>
    <p className='font-thin'>
    Extend endless and effortless control over your corporate security.
    </p>
  </div>
  <Form/>
</div>
    
  )
}

export default HealthCareSecurity
import { Link } from 'react-router-dom';


const CallToAction = () => {
  return (
    <section id='cta' className='bg-slate-400'>
      
    </section>
  );
};

export default CallToAction;

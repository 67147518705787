import React from 'react'
import Ac from '../assets/images/AC.png'
import Am from '../assets/images/Am.jpg'
import Form from './Form'
import Mobile from '../assets/images/iPhone .png'

function ResidentialComplex() {
  return (
    <div className='bg-white'>
        <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row '>
  {/* What's Different */}
  <div className='flex flex-col space-y-12 md:w-1/2'>
    <h2 className='max-w-md text-4xl font-bold text-center md:text-left'>
    Why should someone download our corporate security app?
    
    </h2>
    <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
      Take the first step towards a safer and more efficient future. Aves Security Management System is your trusted partner in security management, offering innovative solutions that empower your team and secure your assets.
    </p>
    <img
      className='p-4 lg:relative lg:-top-10 lg:p-2 lg:right-8 xl:relative xl:-top-10 xl:p-8'
      src={Ac} 
      alt="Aves Security Management System in action, illustrating key features."
    />
  </div>

  {/* Numbered List */}
  <div className='flex flex-col space-y-8 md:w-1/2'>
    {/* List Item 1 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            01
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Comprehensive Security Solutions
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Comprehensive Security Solutions
        </h3>
        <p className='text-darkGrayishBlue'>
        You get all in one security solution for your corporate security needs from AVES
        </p>
      </div>
    </div>

    {/* List Item 2 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            02
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Real-Time Monitoring
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Real-Time Monitoring
        </h3>
        <p className='text-darkGrayishBlue'>
        Watch out for instant alerts and get real time information you need at your fingertips.
        </p>
      </div>
    </div>

    {/* List Item 3 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            03
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Customizable Options
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Customizable Options
        </h3>
        <p className='text-darkGrayishBlue'>
        The app can and should be tailored to the needs and demands in your specific organization/business.

        </p>
      </div>
    </div>

    {/* List Item 4 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            04
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Ease of Use
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Ease of Use
        </h3>
        <p className='text-darkGrayishBlue'>
        This results in the concept of Intuitive design since its adoption and implementation does not take time
        </p>
      </div>
    </div>

    {/* List Item 5 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            05
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Data-Driven Decisions
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Data-Driven Decisions
        </h3>
        <p className='text-darkGrayishBlue'>
        Get analysis and information that you need for effective and improved security standards.

        </p>
      </div>

    </div>
  </div>
  <div>
  </div>
</div>
<p className='p-5 text-center text-3xl font-bold mb-4'>
    Major Characteristics of our Corporate Security App
    </p>
    <div className='bg-white'>
        <div className='container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row '>
  {/* What's Different */}
  <div className='flex flex-col space-y-12 md:w-1/2'>
    <p className='max-w-sm text-center text-darkGrayishBlue md:text-left'>
      Take the first step towards a safer and more efficient future. Aves Security Management System is your trusted partner in security management, offering innovative solutions that empower your team and secure your assets.
    </p>
    <img
      className='p-4 lg:relative lg:-top-10 lg:p-2 lg:right-8 xl:relative xl:-top-10 xl:p-8'
      src={Am} 
      alt="Aves Security Management System in action, illustrating key features."
    />
  </div>

  {/* Numbered List */}
  <div className='flex flex-col space-y-8 md:w-1/2'>
    {/* List Item 1 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            01
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Access Control Management
          </h3>
        </div>
      </div>
      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Access Control Management
        </h3>
        <p className='text-darkGrayishBlue'>
        To provide safe and efficient access for employees and visitors to the premises.
Website access, cloud passes and similar key card systems are some examples.

        </p>
      </div>
    </div>

    {/* List Item 2 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            02
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Incident Reporting
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Incident Reporting
        </h3>
        <p className='text-darkGrayishBlue'>
        Allow staff to report incidents as soon as possible through the help of the app.
        It is essential to keep original records in the central place for easy decision making.
        </p>
      </div>
    </div>

    {/* List Item 3 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            03
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          Visitor Management
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold md:block'>
        Visitor Management
        </h3>
        <p className='text-darkGrayishBlue'>
        Positions can also opt for digital approvals of the visitor to make the check-in process more manageable.
In this way visitors should be accurately counted and also secured most of the time.
        </p>
      </div>
    </div>

    {/* List Item 4 */}
    <div className='flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row'>
      {/* Heading */}
      <div className='rounded-l-full bg-slate-400 md:bg-transparent'>
        <div className='flex items-center space-x-2'>
          <div className='px-4 py-2 text-white rounded-full md:py-1 bg-slate-400'>
            04
          </div>
          <h3 className='text-base font-bold md:mb-4 md:hidden'>
          24/7 Monitoring
          </h3>
        </div>
      </div>

      <div>
        <h3 className='hidden mb-4 text-lg font-bold  md:block'>
        24/7 Monitoring
        </h3>
        <p className='text-darkGrayishBlue  mb-20'>
        This is a continuous surveillance in synergy with the other systems in your premises.
Make sure that your business establishment will always have protection.
        </p>
      </div>
    </div>
      </div>
    </div>
  </div>
  <div>
  </div>
  <h1 className='flex justify-center text-3xl font-bold text-center '>
    How It works
  </h1>
  <div className='flex justify-center items-center mt-8 mb-4'>
    <img className='w-60 h-[450px]' src={Mobile} alt='mobile'/>
  </div>
  <Form/>
</div>
    
  )
}

export default ResidentialComplex
import React from "react";
import { QRCodeCanvas } from 'qrcode.react';

const Qrcode = () => {
  const qrValue = "https://avessecurity.com/Email-submit";

  return (
    <div className="text-center mt-12 flex justify-center items-center pb-10 " style={{ textAlign: "center", marginTop: "50px" }}>
      <h1></h1>
      <QRCodeCanvas className="text-center" value={qrValue} size={200} />
      <p></p>
    </div>
  );
};

export default Qrcode;

import { Link } from 'react-router-dom';


const Testimonial = () => {
  return (
    <section id='testimonials'>
      {/* Container to heading and testm blocks */}
     
    </section>
  );
};

export default Testimonial;
